.u-cf {
  @include clearfix;
}

.u-hidden {
  display: none !important;
}

.u-smaller-line-height {
  line-height: 1.275;
}

.u-mirror-horizontally {
  display: inline-block;
  transform: matrix(-1, 0, 0, 1, 0, 0) !important;
}

.u-noWrap {

  @include breakpoint(xlarge) {
    white-space: nowrap;
  }

}

.u-custom-gutter {

  @include breakpoint(large) {
    margin-left: -0.5rem !important;
    margin-right: -0.8rem !important;
  }

}

.u-0margin {
  margin: 0 !important;
}

.u-center-medium-down {

  @include breakpoint(medium down) {
    text-align: center;
    margin: 0 auto;
    display: block;
  }

}

.u-center-860-down {

  @include breakpoint(860px down) {
    text-align: center;
    margin: 0 auto;
    display: block;
  }

}

.u-center-medium-only {


  @media screen and #{breakpoint(mobile)} and #{breakpoint(medium down)} {
    text-align: center;
    margin: 0 auto;
    display: block;
  }

}

.u-center-mobile-down {

  @include breakpoint(mobile down) {
    text-align: center;
    margin: 0 auto;
    display: block;
  }

}

.u-stacked-medium-down + .u-stacked-medium-down {

  @include breakpoint(medium down) {
    margin-top: 15px;
  }

}

.u-stacked-mobile-down + .u-stacked-mobile-down {

  @include breakpoint(mobile down) {
    margin-top: 15px;
  }

}

.u-mobile-down-clear {

  @include breakpoint(mobile down) {
    clear: left;
  }

}

.u-rotate-345 {
  transform: rotate(345deg) !important;
}

.u-mirror-horizontally-and-rotate-345 {
  display: inline-block;
  transform: matrix(-1, 0, 0, 1, 0, 0) rotate(345deg) !important;
}

.u-rotate-15 {
  transform: rotate(15deg) !important;
}

.u-10paddingTop {
  padding-top: 10px;
}

.u-50paddingTop {
  padding-top: 50px;
}

.u-inverted {
  color: #fff !important;

  a {
    color: #fff !important;
  }

  p a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #e1e1e1 !important;
  }
}

.u-inverted.u-inverted--darker {
  color: #adadad !important;

  a {
    color: #adadad !important;
  }

}


.u-maxWidth80percent {
  max-width: 80% !important;
  margin: 0 auto;
}

.u-video-wrapper-16-9 {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  width: 100%;
  padding-top: 56.2%; /* 16:9, floored normally would be 56.25 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}