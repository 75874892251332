.c-top-bar {
  position: relative;
  @include top-bar-container;
}

.c-top-bar .c-top-bar-inner {
  padding: 0 30px 0 20px;

  @include breakpoint(large down) {
    padding: 0 30px 0 10px;
  }

  @include breakpoint(medium down) {
    padding: 0 5px;
  }

  .top-bar-title {
    margin-right: 4.5rem;
  }

  .top-bar-title img {
    width: 100px;
  }

  @include breakpoint(large) {
    .top-bar-title img {
      transform: scale(1.3);
      transform-origin: left center;
    }
  }
}

.c-top-bar {

  .c-top-bar-searchbar {
    padding-bottom: 1px;
    border-bottom: 1px solid #e1e1e1;
  }

  .c-top-bar-searchbar input {
    background: #fff;
    border: 0;
    outline: none;
    box-shadow: none;
  }

  .c-top-bar-searchbar button {
    background: #fff;
    color: #343434;
    margin-right: -1rem;
    padding-bottom: 1px;
  }

  /*
  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    .c-top-bar-searchbar {
      display: none;
    }
  }
  */
}

.c-top-bar {

  .menu-toggle-container {
    float: right;
  }

  .menu-toggle-container .menu-toggle-inner {
    display: inline-block;
    padding: 0.7rem 1.25rem;
    font-size: 1.15rem;
    border: 1px solid #e1e1e1;
    text-transform: uppercase;
    transition: border 0.2s;

    &:hover {
      border: 1px solid #343434;
    }
  }

  .menu-toggle-container > span button {
    vertical-align: top;
    padding-bottom: 1px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      &:active {
        position: relative;
        top: -1px;
        left: -1px;
      }
    }
  }

  .menu-toggle-container > span button .menu-toggle-description {
    vertical-align: middle;
    margin-right: 5px;
  }

  .menu-toggle-container > span.js-button-toggled button .menu-icon:after {
    box-shadow: none;
    background: transparent;
    font-family: 'FontAwesome';
    content: '\f00d';
    font-style: normal;
    height: 100%;
    top: -1px;
    font-size: 1.3rem;
  }

  .menu a {
    position: relative;
    color: #343434;
    text-transform: uppercase;
    font-size: 1.15rem;
    transition: color 0.2s;
    padding: 0.7rem 1.25rem;

    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 1rem;
      right: 1rem;
      height: 4px;
      background: transparent;
      transition: background 0.2s;
    }

  }


  .dropdown.menu > {

    li + li > a::before {
      content: '/';
      width: 12px;
      display: inline-block;
      position: absolute;
      top: 0.5rem;
      left: 0rem;
      transform: translateX(-50%);
      font-size: 1.7rem;
      color: #ededed;
      border: 0px solid transparent;
      margin-top: 0;
    }

    li:first-child a {

    }

  }

  .menu a:hover,
  .menu .is-active > a,
  .dropdown.menu > li.is-dropdown-submenu-parent a:hover {
    color: #f3c289;

    &:after {
      background: #f3c289;
    }
  }

  .dropdown.menu > li.is-dropdown-submenu-parent > a {
    padding-right: 1.25rem;
  }

  .dropdown.menu > li.is-dropdown-submenu-parent > a:after {
    content: '';
    display: block;
    position: absolute;
    width: auto;
    bottom: 0;
    left: 1rem;
    right: 1rem;
    height: 4px;
    background: transparent;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
    border: none;
  }

  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: auto;
    right: 1rem;
    margin-top: 0;
  }

  .dropdown.menu > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 50%;
    top: calc(100% + 1rem);
    transform: translateX(-50%);
    min-width: 145px;
    padding: 1rem 0.7rem;
    background: #e1e1e1;
    border: 0;

    a:hover {
      color: darken(#f3c289, 5%);
      &:after {
        content: none;
      }
    }

  }

  .dropdown.menu > li.opens-right > .is-dropdown-submenu:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-bottom: 15px solid #e1e1e1;
  }
}

[data-dropdown-menu] ul {
  display: none;
}

.c-top-bar .c-top-bar-menu {
  width: 100%;

  @include breakpoint(medium down) {
    display: none;

    .responsive-menu-inner {
      display: table;
      width: 100%;
    }

  }

}

.c-top-bar .c-top-bar-menu {
  display: none;

  @include breakpoint(medium down) {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #e1e1e1;

    ul {
      background: #e1e1e1;
    }

    box-sizing: border-box;
    z-index: 10;
    width: 100%;

    .top-bar-left,
    .top-bar-right {
      float: none;
    }

    .top-bar-header-group{
      display: table-header-group;
    }

    .top-bar-footer-group{
      display: table-footer-group;
    }

    .dropdown.menu {

      li + li > a::before {
        content: '';
        box-sizing: border-box;
        width: calc(100% - 3.6rem);
        height: 1px;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 1.8rem;
        transform: none;
        background: #d3d3d3;
      }
    }

    .menu li {
      display: block;
    }

    .menu a {
    }

    .menu a {
      position: relative;
      color: #343434;
      text-transform: uppercase;
      font-size: 1.15rem;
      transition: color 0.2s;
      padding: 1rem 1.8rem;

      &:before {
        content: none;
        display: block;
      }

    }

    .menu a:hover,
    .menu .is-active > a {
      color: #f3c289;

      &:before {
        background: #f3c289;
      }

      &:after {
        background: transparent;
      }
    }

    .dropdown.menu > li.is-dropdown-submenu-parent a:hover:after {
      background: transparent;
    }

    .menu li.is-dropdown-submenu-parent > a:after {
      content: '\f196';
      position: static;
      display: inline-block;
      font-size: 1rem;
      margin-left: 5px;
      font-family: 'FontAwesome';
      border: 0;
      line-height: 1;
      height: auto;
    }

    .menu li.is-dropdown-submenu-parent.is-active > a:after {
      content: '\f147';
    }

    .dropdown.menu > li > .is-dropdown-submenu.js-dropdown-active {
      display: block;
      position: static;
      border: 0;
      margin-left: 1.8rem;
      margin-right: 1.8rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      background: #ececec;
      border-top: 1px solid #d3d3d3;
      text-decoration: underline;

      li {
        padding-left: 0.5rem;
      }

      a {
        text-transform: none;
        padding: 1rem 0.5rem;
        &:before {
          visibility: hidden;
        }
      }

    }

    .dropdown.menu > li.is-dropdown-submenu-parent > a {
      padding-right: 1.25rem;
    }

    .dropdown.menu > li.is-dropdown-submenu-parent > a:before {

    }

    .dropdown.menu > li.opens-right > .is-dropdown-submenu {
      position: static;
      background: #ececec;

      a:hover {
        color: darken(#f3c289, 5%);
        &:before {
          content: none;
        }
      }

    }

    .dropdown.menu > li.opens-right > .is-dropdown-submenu:before {
      content: '';
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 25px solid transparent;
      border-right: 25px solid transparent;
      border-bottom: 15px solid #e1e1e1;
    }

  }

}


.c-top-bar {

  .menu.c-top-bar-searchbar.c-top-bar-searchbar--asTitle {
    display: inline-block;
    float: left;
    width: auto;
    padding: 0;
  }

  @include breakpoint(large) {
    .menu.c-top-bar-searchbar.c-top-bar-searchbar--asTitle {
      display: inline-block;
      float: right;
      padding-bottom: 1px;
    }

  }

  @include breakpoint(large) {
    .c-top-bar-menu .top-bar-left > .menu > li:first-child a {
      padding-left: 0rem;

      &:after {
        left: 0rem;
      }
    }
  }

   @include breakpoint(xlarge) {
    .c-top-bar-menu .top-bar-left > .menu > li:first-child a {
      padding-left: 0.65rem;

      &:after {
        left: 0.65rem;
      }
    }
  }

  @include breakpoint(medium down) {

    .c-top-bar-searchbar.menu {
      display: table;
      width: 100%;
      padding: 20px 1.8rem 10px 1.8rem;
    }

    .c-top-bar-searchbar.menu li {
      display: table-cell;
      height: 40px;
      background: #fff;
    }

    .c-top-bar-searchbar input {
      max-width: none;
    }

    .c-top-bar-searchbar .c-top-bar-searchbar__submit {
      width: 40px;

      button {
        padding: 0em 1em;
        font-size: 1.1rem;
        margin-right: 0;
      }
    }

    .c-top-bar-searchbar input,
    .c-top-bar-searchbar button {
      height: 100%;
    }
  }

}