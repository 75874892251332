$icon-ns: '';

.#{$icon-ns}c-icon {
  font-size: 1.3rem;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.#{$icon-ns}c-icon.#{$icon-ns}c-icon--facebook {
  background: #1672b9;
}

.#{$icon-ns}c-icon.#{$icon-ns}c-icon--twitter {
  background: #91cfff;
}

.#{$icon-ns}c-icon.#{$icon-ns}c-icon--rounded {
  border-radius: 20px;
}
